// Lib
import { Box, Typography } from "@mui/material";
import { navigate } from "gatsby";
import React, { Fragment, useState, useMemo, useEffect, useRef } from "react";

// Own components
import { SearchBar, Table, Dialog, ChangeInfusionDate } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import {
    useOrders,
    usePermission,
    useViewingOptions,
    useOrderStatuses,
    useEditInfusionDate,
    useSelectedCountry,
} from "@hooks";

// Types
import { SearchFilters, Permissions } from "@types";

// Utils
import {
    apiResponseCounter,
    constructQueryString,
    isSuccessfulCall,
} from "@utils";

type Order = {
    unlinkable: boolean;
    orderId: string;
    sapOrderNo: string;
    contractId: string;
    contractRef: string;
    contractName: string;
    salessystemOrderId: string;
    productName: string;
    extContractRef: string;
    treatmentSite: string;
    shipTo: string;
    orderStatus: string;
    infusionDate: string;
    orderDate: string;
    brandName: string;
    indicationName: string;
    sapSkuNo: string;
};

/**
 * Orders
 */
const Orders = () => {
    const [orderToUnlink, setOrder] = useState(undefined);
    const [orderToChangeInfusionDate, setOrderToChangeInfusionDate] = useState<
        Order | undefined
    >(undefined);

    /**
     * Search Ref
     */

    const searchRef = useRef<any>();

    /**
     * Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.ORDERS,
    );

    // Get team iso code
    const { isGermanyTeam } = useSelectedCountry();
    /**
     * Permissions
     */
    const {
        hasPermissionToEditOrder,
        hasPermissionToCreateOrder,
        hasPermissionToEditOutcome,
        hasPermissionToReadOrder,
    }: Permissions = usePermission();

    /**
     * API
     */

    // Orders
    const { response, loading, search, reload, unlinkOrder }: any =
        useOrders("orders");

    const {
        loading: editingInfusionDate,
        response: editInfusionDateResponse,
        editInfusionDate,
    }: any = useEditInfusionDate();

    // order statuses
    const { loading: fetchingOrderStatuses, list: orderStatuses } =
        useOrderStatuses();

    useEffect(() => {
        if (!orderToChangeInfusionDate) return;
        if (isSuccessfulCall(editInfusionDateResponse?.status)) {
            setOrderToChangeInfusionDate(undefined);
            searchRef?.current?.onSearchReset();
            reload();
        }
    }, [editInfusionDateResponse]);

    /**
     * Search handler
     */
    const onSearch = (searchFilters: SearchFilters) => {
        const hasFilters = Object.values(searchFilters).some(
            filter => filter.length,
        );

        const params = constructQueryString(searchFilters);

        if (hasFilters) {
            search(params);
        } else reload();
    };

    /**
     * Unlink orders
     */
    const unlink = () => {
        unlinkOrder(orderToUnlink).then(res => {
            if (isSuccessfulCall(res?.status)) {
                setOrder(undefined);
                searchRef?.current?.onSearchReset();
            }
        });
    };

    /**
     * Status mapper
     */
    const mapOrderStatuses = useMemo(() => {
        if (!orderStatuses?.data?.records) return [];
        return orderStatuses?.data?.records?.map(status => {
            return {
                label: status?.label,
                value: status?.id,
            };
        });
    }, [orderStatuses?.data?.records]);

    /**
     * Render
     */
    return (
        <Fragment>
            <ChangeInfusionDate
                id={`change-infusion-date-modal`}
                open={!!orderToChangeInfusionDate}
                onClose={() => {
                    setOrderToChangeInfusionDate(undefined);
                }}
                title={
                    orderToChangeInfusionDate?.infusionDate
                        ? "Edit infusion date"
                        : "Add infusion date"
                }
                onSubmit={infusionDate => {
                    editInfusionDate(
                        orderToChangeInfusionDate?.infusionDate
                            ? "edit"
                            : "add",
                        orderToChangeInfusionDate?.orderId,
                        infusionDate,
                    );
                }}
                loading={editingInfusionDate}
                disabled={editingInfusionDate}
                salessystemOrderId={
                    orderToChangeInfusionDate?.salessystemOrderId || ""
                }
                orderInfusionDate={orderToChangeInfusionDate?.infusionDate}
            />

            <Dialog
                open={!!orderToUnlink}
                id={`orders-delete-condition`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you would like to unlink this order?
                    </Typography>
                }
                primaryButton={{
                    text: "Unlink",
                    action: unlink,
                    loading: loading.unlinkingOrder,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setOrder(undefined),
                }}
            />

            <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id={`dashboard-orders-overview-search-bar`}
                    filterKey={ROWRENDERERCONST.ORDERS}
                    handleSearch={(filters: SearchFilters) => onSearch(filters)}
                    placeholder="Search by cquence Id or treatment site"
                    viewingOptions={viewingOptions}
                    setViewingOptions={setViewingOptions}
                    ref={searchRef}
                    hasPagination={true}
                    status={{
                        data: mapOrderStatuses,
                        loading: fetchingOrderStatuses,
                    }}
                />
            </Box>
            <Box mt={5}>
                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h2">Orders</Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            response?.orders?.data?.records,
                            loading.fetchingOrders,
                            "order|orders",
                        )}
                    </Typography>
                </Box>

                <Table
                    id={`dashboard-orders-overview-list`}
                    headers={HEADERS.ORDERS}
                    rows={response?.orders?.data?.records}
                    loading={loading?.fetchingOrders}
                    type={ROWRENDERERCONST.ORDERS}
                    permissions={{
                        hasPermissionToReadOrder,
                        hasPermissionToCreateOrder,
                        hasPermissionToEditOrder,
                        hasPermissionToEditOutcome,
                        kanLinkWithoutInfusionDate: isGermanyTeam,
                    }}
                    callbacks={{
                        onLinkOrder: row => {
                            navigate(`/order/link-order/${row.orderId}/`);
                        },
                        onEditOrder: row => {
                            navigate(`/order/edit-order/${row.orderId}/`);
                        },
                        onViewOrder: row => {
                            navigate(`/order/view-order/${row.orderId}/`);
                        },
                        onAddOutcome: row =>
                            navigate(`/order/add-outcome/${row.orderId}/`),
                        onUnlinkOrder: row => {
                            setOrder(row.orderId);
                        },
                        onInfusionDateChange: item => {
                            setOrderToChangeInfusionDate(item);
                        },
                    }}
                    viewingOptions={viewingOptions}
                    emptyMsg="No orders found"
                    hasPagination={true}
                    pagesCount={response?.orders?.data?.itemCount}
                />
            </Box>
        </Fragment>
    );
};
export default Orders;
