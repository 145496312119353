//import libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

// import own components
import { ActionMenu } from "@components";

// Import utils
import { displayDate, stringTransformer } from "@utils";

interface Row {
    id: string;
    name: string;
    contractRef: string;
    category: string;
    created: string;
    taskDefinitionKey: string;
    variables: any;
    tenantId: string;
    contractId: string;
    salessystemOrderId?: string;
    approved: boolean;
    supplierName: string;
    permissions: Record<string, boolean>;
    recalled?: boolean | null;
    claimNo?: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    permissions: any;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
    isCompleted?: boolean;
}

const TasksRow = ({
    id,
    row,
    rowIndex,
    isColVisible,
    callbacks,
    isCompleted = false,
    permissions,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
        >
            {isColVisible("name") && (
                <TableCell>
                    <Typography variant="caption2">{row.name}</Typography>
                </TableCell>
            )}

            {isColVisible("contractRef") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.contractRef || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("taskDefinitionKey") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.taskDefinitionKey
                            ? stringTransformer(row.taskDefinitionKey)
                            : ""}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("category") && (
                <TableCell>
                    <Typography variant="caption2">{row.category}</Typography>
                </TableCell>
            )}

            {isColVisible("salessystemOrderId") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.salessystemOrderId ? row.salessystemOrderId : "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("supplierName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.supplierName ? row.supplierName : "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("claimNo") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.claimNo ? row.claimNo : "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("created") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.created)}
                    </Typography>
                </TableCell>
            )}

            {isCompleted && isColVisible("approved") && (
                <TableCell>
                    <Typography variant="caption2">
                        {typeof row.approved === "boolean"
                            ? row.approved
                                ? "Yes"
                                : `No ${(row?.taskDefinitionKey === "claimApprovalTask" || row?.taskDefinitionKey === "claimOverrideApprovalTask") && !!row?.recalled ? "(Recalled)" : ""}`
                            : "-"}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-${rowIndex}-task-row`}
                        options={[
                            /*   {
                                label: "View contract",
                                action: () =>
                                    callbacks &&
                                    callbacks?.viewContract(
                                        row?.contractId,
                                        row?.contractRef,
                                    ),
                            }, */
                            {
                                label: "View task details",
                                action: () =>
                                    callbacks &&
                                    callbacks?.viewTaskDetails(row?.id),
                                disabled:
                                    row.taskDefinitionKey ===
                                        "priceDataApprovalTask" &&
                                    !permissions?.hasPermissionToEditDataTables,
                            },
                        ]}
                        rowIndex={rowIndex}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default TasksRow;
