// Lib
import {
    Box,
    TextField,
    Typography,
    InputLabel,
    InputAdornment,
    Tooltip,
    CircularProgress,
    Grid,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React, { Fragment, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";

// Own components
import { Table, SearchBar, Dropzone } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import { useFinance } from "@hooks";

// Types
import { SearchFilters } from "@types";

// Utils
import { apiResponseCounter, constructQueryString } from "@utils";

/**
 * Props type
 */
interface Props {
    selectedOutcomes?: Array<string>;
    onChange?: (key: string, value: any) => void;
    bpfName?: string;
    setTouch?: (values: Record<string, boolean>) => void;
    validateBpfName?: (id: string | undefined, data: any) => void;
    validatePaymentReference?: (id: string | undefined, data: any) => void;
    touch?: Record<string, boolean>;
    bpfNameValidation?: any;
    contractId?: string;
    paymentReference?: string;
    paymentReferenceValidation?: Record<string, boolean>;
    disabled?: boolean;
    isApheresisBased?: boolean;
    uploadFile?: File;
    setFileToUpload?: (file: File | undefined) => void;
}

const BPF_NAME_REGEX = /^[a-zA-Z0-9\s]*$/;

/**
 * Select Outcomes
 */
const SelectOutcomes = ({
    selectedOutcomes,
    onChange,
    bpfName,
    setTouch,
    validateBpfName,
    touch,
    bpfNameValidation,
    contractId,
    validatePaymentReference,
    paymentReference,
    paymentReferenceValidation,
    disabled,
    isApheresisBased,
    uploadFile,
    setFileToUpload,
}: Props) => {
    // Get outcomes
    const {
        list: outcomesList,
        loading: { fetching, creating },
        search: getOutcomes,
    } = useFinance("bpf", "outcome|outcomes", "outcomes");

    /**
     * Outcome check Status
     */
    const findOutcome = useCallback(
        (id: string) => {
            if (!id || !selectedOutcomes) return;
            return !!selectedOutcomes.find(item => item === id);
        },
        [selectedOutcomes],
    );

    /**
     * Select outcomes handler
     */
    const handleSelectOutcomes = (id: string) => {
        if (!onChange) return;
        const element = selectedOutcomes?.find(el => el === id);
        const clonedOutcomes: any = selectedOutcomes
            ? [...selectedOutcomes]
            : [];
        if (element) {
            const updatedOutcomes = clonedOutcomes.filter(el => el !== id);
            onChange("outcomes", updatedOutcomes);
        } else {
            clonedOutcomes.push(id);
            onChange("outcomes", clonedOutcomes);
        }
    };

    /**
     * Handle search
     */
    const onSearch = (filters: SearchFilters) => {
        const hasFilters = Object.values(filters).some(filter => filter.length);
        const params = constructQueryString(filters);

        if (hasFilters) {
            getOutcomes(`?contractId=${contractId}${params}`);
        } else getOutcomes(`?contractId=${contractId}`);
    };

    /**
     * Debouncers
     */
    // BPF name debouncer
    const debounceLoadData = useCallback(
        debounce(
            name =>
                !!validateBpfName &&
                validateBpfName(undefined, { bpfName: name }),
            600,
        ),
        [],
    );

    useEffect(() => {
        if (!bpfName) {
            return;
        }
        debounceLoadData(bpfName);
    }, [bpfName]);

    // Payment reference debouncer
    const debounceLoadPaymentReferenceData = useCallback(
        debounce(
            name =>
                !!validatePaymentReference &&
                validatePaymentReference(undefined, { paymentReference: name }),
            600,
        ),
        [],
    );

    useEffect(() => {
        if (!paymentReference) {
            return;
        }
        debounceLoadPaymentReferenceData(paymentReference);
    }, [paymentReference]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Typography variant="h2" mb={1}>
                BPF generation
            </Typography>

            <Grid container mt={3} mb={7} spacing={3}>
                {isApheresisBased && (
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={
                                (!paymentReference &&
                                    touch?.paymentReference) ||
                                paymentReferenceValidation?.isPaymentReferenceNotUniqueValue
                            }
                            shrink
                        >
                            {"Payment Registration Number (*)"}
                        </InputLabel>
                        <TextField
                            id={`generate-bpf-paymentRef-input`}
                            disabled={disabled}
                            fullWidth
                            size="small"
                            name="bpf"
                            autoComplete="off"
                            value={paymentReference}
                            onBlur={
                                setTouch
                                    ? () =>
                                          setTouch({
                                              ...touch,
                                              paymentReference: true,
                                          })
                                    : undefined
                            }
                            error={
                                (!paymentReference &&
                                    touch?.paymentReference) ||
                                paymentReferenceValidation?.isPaymentReferenceNotUniqueValue
                            }
                            onChange={(event: React.BaseSyntheticEvent) => {
                                if (onChange) {
                                    if (event.target.value.startsWith(" ")) {
                                        onChange(
                                            "paymentReference",
                                            event.target.value.trimStart(),
                                        );
                                    } else
                                        onChange(
                                            "paymentReference",
                                            event.target.value,
                                        );
                                }
                            }}
                            variant="outlined"
                            InputProps={
                                paymentReferenceValidation?.paymentReferenceDuplicationValidating
                                    ? {
                                          endAdornment: (
                                              <InputAdornment position="start">
                                                  <CircularProgress
                                                      color="inherit"
                                                      size={20}
                                                  />
                                              </InputAdornment>
                                          ),
                                      }
                                    : paymentReferenceValidation?.isPaymentReferenceNotUniqueValue
                                      ? {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        open={true}
                                                        arrow
                                                        title={
                                                            paymentReferenceValidation?.isPaymentReferenceNotUniqueMessage
                                                        }
                                                        placement="top"
                                                    >
                                                        <InfoIcon color="error" />
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }
                                      : undefined
                            }
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    <InputLabel
                        error={
                            (!bpfName && touch?.bpfName) ||
                            bpfNameValidation?.isBpfNameNotUniqueValue
                        }
                        shrink
                    >
                        {"BPF name (*)"}
                    </InputLabel>
                    <TextField
                        id={`generate-bpf-bpf-input`}
                        disabled={creating}
                        fullWidth
                        size="small"
                        name="bpf"
                        autoComplete="off"
                        value={bpfName}
                        onBlur={
                            setTouch
                                ? () => setTouch({ ...touch, bpfName: true })
                                : undefined
                        }
                        error={
                            (!bpfName && touch?.bpfName) ||
                            bpfNameValidation?.isBpfNameNotUniqueValue
                        }
                        onChange={(event: React.BaseSyntheticEvent) => {
                            if (
                                onChange &&
                                BPF_NAME_REGEX.test(event.target.value)
                            ) {
                                if (event.target.value.startsWith(" ")) {
                                    onChange(
                                        "bpfName",
                                        event.target.value.trimStart(),
                                    );
                                } else onChange("bpfName", event.target.value);
                            }
                        }}
                        variant="outlined"
                        InputProps={
                            bpfNameValidation?.bpfNameDuplicationValidating
                                ? {
                                      endAdornment: (
                                          <InputAdornment position="start">
                                              <CircularProgress
                                                  color="inherit"
                                                  size={20}
                                              />
                                          </InputAdornment>
                                      ),
                                  }
                                : bpfNameValidation?.isBpfNameNotUniqueValue
                                  ? {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    open={true}
                                                    arrow
                                                    title={
                                                        bpfNameValidation?.isBpfNameNotUniqueMessage
                                                    }
                                                    placement="top"
                                                >
                                                    <InfoIcon color="error" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }
                                  : undefined
                        }
                    />
                </Grid>
                {isApheresisBased && !!setFileToUpload && (
                    <Grid item xs={12} mt={4}>
                        <InputLabel
                            shrink
                            error={!uploadFile && touch?.uploadFile}
                        >
                            {"Evidence (*)"}
                        </InputLabel>
                        <Dropzone
                            disabled={!!uploadFile}
                            variant="big"
                            id={"upload-contract-document"}
                            fileTypes={{
                                "application/pdf": [".pdf"],
                                "image/png": [".png"],
                                "image/jpeg": [".jpeg", ".jpg"],
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                    [".docx"],
                                "application/msword": [".doc"],
                                "application/vnd.ms-excel": [".xls"],
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                    [".xlsx"],
                                "application/vnd.ms-outlook": [".msg"],
                            }}
                            files={[uploadFile]}
                            onUpload={uploadFiles => {
                                setTouch &&
                                    setTouch({ ...touch, uploadFile: true });
                                setFileToUpload(uploadFiles);
                            }}
                            onAttachmentRemove={() =>
                                setFileToUpload(undefined)
                            }
                        />
                    </Grid>
                )}
            </Grid>

            <SearchBar
                id={`generate-bpf-bpf-generation`}
                handleSearch={(filters: SearchFilters) => onSearch(filters)}
                simpleLayout
                searchTextOnly
                placeholder="Search by cquence Id"
                searchCallback={
                    onChange ? () => onChange("outcomes", []) : undefined
                } // reset on search change
            />

            <Box display="flex" alignItems="baseline" mb={4}>
                <Typography variant="h3">Search results</Typography>

                <Typography ml={1} variant="caption1">
                    {apiResponseCounter(
                        outcomesList,
                        fetching,
                        "outcome|outcomes",
                    )}
                </Typography>
            </Box>

            <Table
                id={`generate-bpf-list`}
                headers={HEADERS.FINANCES_OUTCOMES}
                rows={outcomesList?.data?.records}
                loading={fetching}
                type={ROWRENDERERCONST.FINANCES_OUTCOMES}
                disabled={creating}
                callbacks={{
                    onOutcomeSelect: handleSelectOutcomes,
                    onFindOutcome: (id: string) => findOutcome(id),
                }}
                emptyMsg="No data"
                maxHeight="38rem"
            />
            <Box>
                <Typography variant="caption1" color="primary">
                    {`${selectedOutcomes?.length} order selected`}
                </Typography>
            </Box>
        </Fragment>
    );
};
export default SelectOutcomes;
