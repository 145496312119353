// Icons
import InfoIcon from "@mui/icons-material/Info";

// Lib
import {
    Box,
    TextField,
    Typography,
    InputLabel,
    InputAdornment,
    Tooltip,
    CircularProgress,
    Grid,
} from "@mui/material";
import debounce from "lodash.debounce";
import React, { Fragment, useCallback, useEffect } from "react";

// Own components
import { Table, SearchBar } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import { useFinance } from "@hooks";

// Types
import { SearchFilters } from "@types";

// Utils
import { apiResponseCounter, constructQueryString } from "@utils";

const BPF_NAME_REGEX = /^[a-zA-Z0-9\s]*$/;

/**
 * Props type
 */
interface Props {
    selectedClaims?: (
        type: "claims" | "priceCorrectionClaims",
    ) => Array<string>;
    onChange?: (key: string, value: any) => void;
    bpfName?: string;
    setTouch?: (values: Record<string, boolean>) => void;
    validateBpfName?: (id: string | undefined, data: any) => void;
    validatePaymentReference?: (id: string | undefined, data: any) => void;
    touch?: Record<string, boolean>;
    bpfNameValidation?: Record<string, boolean>;
    paymentReferenceValidation?: Record<string, boolean>;
    disabled?: boolean;
    paymentReference?: string;
    contractId?: string;
}

/**
 * Select Claims
 */
const SelectClaims = ({
    selectedClaims,
    onChange,
    bpfName,
    setTouch,
    validateBpfName,
    validatePaymentReference,
    touch,
    bpfNameValidation,
    disabled,
    paymentReference,
    paymentReferenceValidation,
    contractId,
}: Props) => {
    // Get claims
    const {
        list: claimList,
        loading: { fetching: ClaimsLoading },
        search,
    } = useFinance("bpf", "claim|claims", "claims");

    // Get price correction claims
    const {
        list: priceCorrectionClaimsList,
        loading: { fetching: priceCorrectionClaimsLoading },
        search: searchPriceCorrectionClaims,
    } = useFinance("bpf", "claim|claims", "claims");

    /**
     * Select claims handler
     */
    const handleSelectClaims = (
        id: string,
        type: "claims" | "priceCorrectionClaims",
    ) => {
        if (!onChange || !selectedClaims) return;
        const storedClaims = selectedClaims(type);
        const index = storedClaims?.findIndex(el => el === id);
        const clonedClaims: string[] = storedClaims ? [...storedClaims] : [];
        if (index !== undefined && index > -1) {
            const updatedClaims = clonedClaims.filter(
                (_, idx) => index !== idx,
            );
            onChange(type, updatedClaims);
        } else {
            clonedClaims.push(id);
            onChange(type, clonedClaims);
        }
    };

    /**
     * Handle search
     */
    const onSearch = (filters: SearchFilters, isPriceCorrection: boolean) => {
        const hasFilters = Object.values(filters).some(filter => filter.length);

        const params = constructQueryString(filters);

        if (hasFilters) {
            isPriceCorrection
                ? searchPriceCorrectionClaims(
                      `?contractId=${contractId}${params}&priceCorrectionStatus=OPEN`,
                  )
                : search(`?contractId=${contractId}${params}`);
        } else
            isPriceCorrection
                ? searchPriceCorrectionClaims(
                      `?contractId=${contractId}&priceCorrectionStatus=OPEN`,
                  )
                : search(`?contractId=${contractId}`);
    };

    /**
     * Debouncers
     */
    const debounceLoadBpfNameData = useCallback(
        debounce(
            name =>
                !!validateBpfName &&
                validateBpfName(undefined, { bpfName: name }),
            600,
        ),
        [],
    );

    useEffect(() => {
        if (!bpfName) {
            return;
        }
        debounceLoadBpfNameData(bpfName);
    }, [bpfName]);

    /**
     * Debouncers
     */
    const debounceLoadPaymentReferenceData = useCallback(
        debounce(
            name =>
                !!validatePaymentReference &&
                validatePaymentReference(undefined, { paymentReference: name }),
            600,
        ),
        [],
    );

    useEffect(() => {
        if (!paymentReference) {
            return;
        }
        debounceLoadPaymentReferenceData(paymentReference);
    }, [paymentReference]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Typography variant="h2" mb={1}>
                BPF generation
            </Typography>

            <Grid container mt={3} mb={7} spacing={3}>
                <Grid item xs={12} md={6}>
                    <InputLabel
                        error={
                            (!paymentReference && touch?.paymentReference) ||
                            paymentReferenceValidation?.isPaymentReferenceNotUniqueValue
                        }
                        shrink
                    >
                        {"Payment Registration Number (*)"}
                    </InputLabel>
                    <TextField
                        id={`generate-bpf-paymentRef-input`}
                        disabled={disabled}
                        fullWidth
                        size="small"
                        name="bpf"
                        autoComplete="off"
                        value={paymentReference}
                        onBlur={
                            setTouch
                                ? () =>
                                      setTouch({
                                          ...touch,
                                          paymentReference: true,
                                      })
                                : undefined
                        }
                        error={
                            (!paymentReference && touch?.paymentReference) ||
                            paymentReferenceValidation?.isPaymentReferenceNotUniqueValue
                        }
                        onChange={(event: React.BaseSyntheticEvent) => {
                            if (onChange) {
                                if (event.target.value.startsWith(" ")) {
                                    onChange(
                                        "paymentReference",
                                        event.target.value.trimStart(),
                                    );
                                } else
                                    onChange(
                                        "paymentReference",
                                        event.target.value,
                                    );
                            }
                        }}
                        variant="outlined"
                        InputProps={
                            paymentReferenceValidation?.paymentReferenceDuplicationValidating
                                ? {
                                      endAdornment: (
                                          <InputAdornment position="start">
                                              <CircularProgress
                                                  color="inherit"
                                                  size={20}
                                              />
                                          </InputAdornment>
                                      ),
                                  }
                                : paymentReferenceValidation?.isPaymentReferenceNotUniqueValue
                                  ? {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    open={true}
                                                    arrow
                                                    title={
                                                        paymentReferenceValidation?.isPaymentReferenceNotUniqueMessage
                                                    }
                                                    placement="top"
                                                >
                                                    <InfoIcon color="error" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }
                                  : undefined
                        }
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel
                        error={
                            (!bpfName && touch?.bpfName) ||
                            bpfNameValidation?.isBpfNameNotUniqueValue
                        }
                        shrink
                    >
                        {"Bpf name (*)"}
                    </InputLabel>
                    <TextField
                        id={`generate-bpf-claim-reference`}
                        disabled={disabled}
                        fullWidth
                        size="small"
                        name="bpf"
                        autoComplete="off"
                        value={bpfName}
                        onBlur={
                            setTouch
                                ? () => setTouch({ ...touch, bpfName: true })
                                : undefined
                        }
                        error={
                            (!bpfName && touch?.bpfName) ||
                            bpfNameValidation?.isBpfNameNotUniqueValue
                        }
                        onChange={(event: React.BaseSyntheticEvent) => {
                            if (
                                onChange &&
                                BPF_NAME_REGEX.test(event.target.value)
                            ) {
                                if (event.target.value.startsWith(" ")) {
                                    onChange(
                                        "bpfName",
                                        event.target.value.trimStart(),
                                    );
                                } else onChange("bpfName", event.target.value);
                            }
                        }}
                        variant="outlined"
                        InputProps={
                            bpfNameValidation?.bpfNameDuplicationValidating
                                ? {
                                      endAdornment: (
                                          <InputAdornment position="start">
                                              <CircularProgress
                                                  color="inherit"
                                                  size={20}
                                              />
                                          </InputAdornment>
                                      ),
                                  }
                                : bpfNameValidation?.isBpfNameNotUniqueValue
                                  ? {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    open={true}
                                                    arrow
                                                    title={
                                                        bpfNameValidation?.isBpfNameNotUniqueMessage
                                                    }
                                                    placement="top-start"
                                                >
                                                    <InfoIcon color="error" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }
                                  : undefined
                        }
                    />
                </Grid>
            </Grid>

            <Box mb={3}>
                <Box mb={4}>
                    <Typography variant="h3">Claims</Typography>
                </Box>

                <SearchBar
                    id="dashboard-claims"
                    handleSearch={(filters: SearchFilters) =>
                        onSearch(filters, false)
                    }
                    placeholder="Search by supplier or filename..."
                    startDate={false}
                    endDate={false}
                    searchTextOnly
                    simpleLayout
                    searchCallback={
                        onChange ? () => onChange("claims", []) : undefined
                    }
                />

                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h3">Search results</Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            claimList,
                            ClaimsLoading,
                            "claim|claims",
                        )}
                    </Typography>
                </Box>

                <Table
                    id={`generate-bpf-claim-list`}
                    headers={HEADERS.BPF_CLAIMS}
                    rows={claimList?.data?.records}
                    loading={ClaimsLoading}
                    type={ROWRENDERERCONST.BPF_CLAIMS}
                    disabled={disabled}
                    callbacks={{
                        onSelect: (claimHeaderId: string) =>
                            handleSelectClaims(claimHeaderId, "claims"),
                        selectedItems:
                            !!selectedClaims && selectedClaims("claims"),
                    }}
                    emptyMsg="No data"
                    maxHeight="38rem"
                />
                <Box mt={1}>
                    <Typography variant="caption1" color="primary">
                        {`${!!selectedClaims && selectedClaims("claims")?.length} ${
                            selectedClaims &&
                            selectedClaims("claims")?.length > 1
                                ? "claims"
                                : "claim"
                        } selected`}
                    </Typography>
                </Box>
            </Box>

            <Box mt={8}>
                <Box mb={4}>
                    <Typography variant="h3">
                        Price correction claims
                    </Typography>
                </Box>

                <SearchBar
                    id="dashboard-claims"
                    handleSearch={(filters: SearchFilters) =>
                        onSearch(filters, true)
                    }
                    placeholder="Search by supplier or filename..."
                    startDate={false}
                    endDate={false}
                    searchTextOnly
                    simpleLayout
                    searchCallback={
                        onChange
                            ? () => onChange("priceCorrectionClaims", [])
                            : undefined
                    } // reset on search change
                />

                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h3">Search results</Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            priceCorrectionClaimsList,
                            priceCorrectionClaimsLoading,
                            "price correction claim|price correction claims",
                        )}
                    </Typography>
                </Box>

                <Table
                    id={`generate-bpf-claim-list`}
                    headers={HEADERS.BPF_PRICE_CORRECTION_CLAIMS}
                    rows={priceCorrectionClaimsList?.data?.records}
                    loading={priceCorrectionClaimsLoading}
                    type={ROWRENDERERCONST.BPF_PRICE_CORRECTION_CLAIMS}
                    disabled={disabled}
                    callbacks={{
                        onSelect: (claimHeaderId: string) =>
                            handleSelectClaims(
                                claimHeaderId,
                                "priceCorrectionClaims",
                            ),
                        selectedItems:
                            !!selectedClaims &&
                            selectedClaims("priceCorrectionClaims"),
                    }}
                    emptyMsg="No data"
                    maxHeight="38rem"
                />
                <Box mt={1}>
                    <Typography variant="caption1" color="primary">
                        {`${selectedClaims && selectedClaims("priceCorrectionClaims")?.length} ${
                            selectedClaims &&
                            selectedClaims("priceCorrectionClaims")?.length > 1
                                ? "Price correction claims"
                                : "Price correction claim"
                        } selected`}
                    </Typography>
                </Box>
            </Box>
        </Fragment>
    );
};
export default React.memo(SelectClaims);
