// Libs
import {
    Box,
    CircularProgress,
    Table as _Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
} from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";

// Utils
import { isArrayWithContent } from "@utils";

// Own components
import Rows from "./Rows";

// Style
import * as style from "./style.module.scss";

// Types
import { ViewingOption } from "@types";
import { Brand } from "types/api";

// Atoms
import { paginationState } from "@atoms";

/**
 * Props type
 */
interface Props {
    headers: Array<{ header: string; viewingOption?: string; rowKey?: string }>;
    rows: Array<any>;
    loading?: boolean;
    type: string;
    callbacks?: any;
    id: string;
    mapper?: (key: string, value: any) => any;
    permissions?: any;
    selectedRow?: string | number | string[];
    maxHeight?: string;
    viewingOptions?: Array<ViewingOption>;
    emptyMsg: React.ReactNode;
    brands?: Array<Brand>;
    disabled?: boolean;
    error?: boolean;
    minWidth?: number | string;
    specificKeys?: Record<string, unknown>;
    size?: "small" | "medium";
    hasPagination?: boolean;
    pagesCount?: number;
}

/**
 * Table
 */
const Table = ({
    headers,
    rows,
    loading,
    type,
    callbacks,
    id,
    mapper,
    maxHeight = "auto",
    permissions,
    selectedRow,
    viewingOptions,
    emptyMsg,
    brands,
    disabled,
    error,
    minWidth = 650,
    specificKeys,
    size,
    hasPagination,
    pagesCount,
}: Props) => {
    const [pagination, setPagination] = useRecoilState(paginationState);

    const handlePageChange = (_, newPage) => {
        setPagination(prev => ({
            ...prev,
            page: newPage + 1, // Add 1 because we store 1-based in state
        }));
    };

    // Convert 1-based page number to 0-based for MUI
    const muiPageNumber = pagination.page - 1;

    return (
        <div>
            <TableContainer
                sx={{
                    maxHeight: maxHeight,
                }}
                className={style.tableContainer}
            >
                <_Table
                    stickyHeader={!!maxHeight}
                    sx={{ minWidth }}
                    aria-label={`${type}-table`}
                    size={size}
                >
                    <TableHead className={style.header}>
                        <TableRow>
                            {headers.map(
                                (item, headIndex) =>
                                    (!item?.header ||
                                        (!!item?.header && !viewingOptions) ||
                                        (!!item?.header &&
                                            !!viewingOptions &&
                                            viewingOptions[headIndex]
                                                ?.checked)) && (
                                        <TableCell key={`${headIndex}`}>
                                            <Typography
                                                className={style.headerItem}
                                                component="span"
                                            >
                                                {item?.header?.includes("|") ? (
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                        component="span"
                                                    >
                                                        <span>
                                                            {
                                                                item.header.split(
                                                                    "|",
                                                                )[0]
                                                            }
                                                        </span>
                                                        <span>
                                                            {
                                                                item.header.split(
                                                                    "|",
                                                                )[1]
                                                            }
                                                        </span>
                                                    </Box>
                                                ) : (
                                                    item.header
                                                )}
                                            </Typography>
                                        </TableCell>
                                    ),
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {loading && (
                            <TableRow sx={{ "& td": { border: 0 } }}>
                                <TableCell
                                    align="center"
                                    colSpan={headers.length}
                                    className={style.loadingOrNoContent}
                                >
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}

                        {!loading && !isArrayWithContent(rows) && (
                            <TableRow sx={{ "& td": { border: 0 } }}>
                                <TableCell
                                    align="center"
                                    colSpan={headers.length}
                                >
                                    <Typography
                                        variant="caption1"
                                        id={`${id}-msg`}
                                    >
                                        {error
                                            ? "Something went wrong, please try again"
                                            : emptyMsg}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}

                        {(hasPagination
                            ? isArrayWithContent(rows)
                            : !loading && isArrayWithContent(rows)) && (
                            <Rows
                                type={type}
                                rows={rows}
                                callbacks={callbacks}
                                id={id}
                                mapper={mapper}
                                permissions={permissions}
                                selectedRow={selectedRow}
                                viewingOptions={viewingOptions}
                                brands={brands}
                                disabled={disabled}
                                specificKeys={specificKeys}
                            />
                        )}
                    </TableBody>
                </_Table>
            </TableContainer>
            {hasPagination && isArrayWithContent(rows) && !!pagesCount && (
                <TablePagination
                    component="div"
                    count={pagesCount}
                    page={muiPageNumber} // Convert to 0-based
                    onPageChange={handlePageChange}
                    rowsPerPage={50}
                    rowsPerPageOptions={[]}
                    disabled={loading}
                />
            )}
        </div>
    );
};

export default React.memo(Table);
