// Lib
import { Box, Typography } from "@mui/material";
import { navigate } from "gatsby";
import React, { Fragment, useMemo, useState, useRef } from "react";
import { useResetRecoilState } from "recoil";

// Own components
import {
    SearchBar,
    Table,
    Dialog,
    ChangeContractDate,
    ChangeContractICD,
} from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Recoil
import { createContractMapper } from "@selectors";

// Hooks
import {
    useBrands,
    useGetContracts,
    usePermission,
    useProducts,
    useTherapeuticAreas,
    useViewingOptions,
    useContractPartners,
    useCancelContract,
    useChangeContractDate,
    useContractStatuses,
    useChangeContractIcd,
} from "@hooks";

// Types
import { SearchFilters, Permissions } from "@types";

// Utils
import {
    apiResponseCounter,
    constructQueryString,
    isSuccessfulCall,
} from "@utils";

/**
 * Contracts
 */
const Contracts = () => {
    /**
     * States
     */
    const resetCreateContract = useResetRecoilState(createContractMapper);

    const [contractToCancel, setContractToCancel] = useState<
        | {
              id: string;
              reference: string;
          }
        | undefined
    >(undefined);

    const [contractIcd, setContractIcd] = useState<{
        id: string;
        oldIcdNo: string | null | undefined;
        oldIcdUrl: string | null | undefined;
        reference: string | undefined;
    }>();

    const [contractDate, setContractDate] = useState<{
        contractId: string;
        reference: string;
        validFrom: string;
        validTo: string;
    }>({
        contractId: "",
        reference: "",
        validFrom: "",
        validTo: "",
    });

    /**
     * Search Ref
     */

    const searchRef = useRef<any>();

    /**
     * Permissions
     */
    const {
        hasPermissionToEditContract,
        hasPermissionToReadContract,
        hasPermissionToCreateContract,
        hasPermissionToReviewContract,
        hasPermissionToCreateOrder,
        hasPermissionToEditOutcome,
    }: Permissions = usePermission();

    /**
     * Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.CONTRACTS,
    );

    /**
     * API
     */
    const {
        list: contractsList,
        loading: fetchingContracts,
        reload: getContracts,
        search,
    } = useGetContracts(false, true);

    // Cancel contract
    const { loading: cancelingContract, cancel: cancelContract } =
        useCancelContract();

    // Change contract date
    const { loading: changingContractDate, load: changeContractDate } =
        useChangeContractDate();

    // Change contract icd
    const { loading: changingContractIcd, load: changeContractIcd } =
        useChangeContractIcd();

    // Brands
    const { list: brands, loading: brandsLoading } = useBrands();

    // Therapeutic area
    const { response: therapeuticAreas, loading: therapeuticAreasLoading } =
        useTherapeuticAreas();

    //Products
    const { response: product, loading: productLoading } = useProducts();

    //Partners
    const {
        getPartners,
        response: partners,
        loading: partnersLoading,
    } = useContractPartners();

    /**
     * contract statuses
     */
    const { loading: fetchingContractStatuses, list: contractStatuses } =
        useContractStatuses();

    const onSearch = (filters: SearchFilters) => {
        const hasFilters = Object.values(filters).some(filter => filter.length);

        const params = constructQueryString(filters);
        if (hasFilters) search(`${params}`);
        else getContracts();
    };

    const onContractCancel = () => {
        if (!contractToCancel?.id) return;
        cancelContract(contractToCancel.id).then(res => {
            if (!!res && isSuccessfulCall(res?.status)) {
                setContractToCancel(undefined);
                searchRef?.current?.onSearchReset();
            }
        });
    };

    const mapContractStatuses = useMemo(() => {
        if (!contractStatuses?.data) return [];
        return contractStatuses?.data?.map(status => {
            return {
                label: status?.contractStatusName,
                value: status?.contractStatusId,
            };
        });
    }, [contractStatuses?.data]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Dialog
                id="dashboard-contracts-cancel-contract"
                title={"Cancel contract"}
                open={!!contractToCancel?.id}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you would like to cancel{" "}
                        <Typography
                            variant="body2"
                            component="span"
                        >{`(${contractToCancel?.reference})? `}</Typography>
                    </Typography>
                }
                primaryButton={{
                    text: "Confirm",
                    action: onContractCancel,
                    loading: cancelingContract,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setContractToCancel(undefined),
                }}
            />

            <ChangeContractDate
                id={"dashboard-contracts-change-contract-date"}
                open={!!contractDate?.contractId}
                onClose={() =>
                    setContractDate({
                        contractId: "",
                        reference: "",
                        validFrom: "",
                        validTo: "",
                    })
                }
                onSubmit={(dateRange, comment) => {
                    changeContractDate({
                        ...dateRange,
                        comment,
                        contractId: contractDate?.contractId,
                    }).then(res => {
                        if (!!res && isSuccessfulCall(res?.status)) {
                            setContractDate({
                                contractId: "",
                                reference: "",
                                validFrom: "",
                                validTo: "",
                            });
                            searchRef?.current?.onSearchReset();
                        }
                    });
                }}
                title={`Change contract date [${contractDate?.reference}]`}
                loading={changingContractDate}
                disabled={changingContractDate}
                oldStartDate={contractDate?.validFrom}
                oldEndDate={contractDate?.validTo}
            />

            <ChangeContractICD
                id={"dashboard-contracts-change-contract-date"}
                open={!!contractIcd?.id}
                onClose={() => setContractIcd(undefined)}
                onSubmit={values => {
                    changeContractIcd({
                        icdNo: values?.icdNo,
                        icdUrl: values?.icdUrl,
                        contractId: contractIcd?.id as string,
                    }).then(res => {
                        if (!!res && isSuccessfulCall(res?.status)) {
                            setContractIcd(undefined);
                            searchRef?.current?.onSearchReset();
                        }
                    });
                }}
                title={`Change contract ICD [${contractIcd?.reference}]`}
                loading={changingContractIcd}
                disabled={changingContractIcd}
                oldIcdNo={contractIcd?.oldIcdNo}
                oldIcdUrl={contractIcd?.oldIcdUrl}
            />

            <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id="dashboard-contracts"
                    ref={searchRef}
                    filterKey={ROWRENDERERCONST.CONTRACTS}
                    handleSearch={(filters: SearchFilters) => onSearch(filters)}
                    products={{
                        data: product?.data?.records,
                        loading: productLoading,
                    }}
                    hasPagination={true}
                    therapeuticAreas={{
                        data: therapeuticAreas?.data,
                        loading: therapeuticAreasLoading,
                    }}
                    contractType
                    brands={{ data: brands?.data, loading: brandsLoading }}
                    action={{
                        type: "menu",
                        label: "New contract",
                        callback: (_, key) => {
                            key === "volume-based"
                                ? navigate(`/create-contract/${key}/model/`)
                                : navigate(
                                      `/create-contract/${key}/general-information/`,
                                  );
                            resetCreateContract();
                        },
                        hidden: !hasPermissionToCreateContract,
                        items: [
                            {
                                label: "Performance based",
                                key: "performance-based",
                            },
                            { label: "Volume based", key: "volume-based" },
                        ],
                    }}
                    partners={{
                        data: partners?.data?.partners,
                        loading: partnersLoading,
                        onSearch: (query: string) => getPartners(query),
                    }}
                    status={{
                        data: mapContractStatuses,
                        loading: fetchingContractStatuses,
                    }}
                    placeholder="Search by contract reference..."
                    viewingOptions={viewingOptions}
                    setViewingOptions={setViewingOptions}
                />
            </Box>
            <Box mt={5}>
                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h2">Contracts</Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            contractsList,
                            fetchingContracts,
                            "contract|contracts",
                        )}
                    </Typography>
                </Box>
                <Table
                    id="dashboard-contracts-list"
                    headers={HEADERS.CONTRACTS}
                    rows={contractsList?.data?.records}
                    loading={fetchingContracts}
                    type={ROWRENDERERCONST.CONTRACTS}
                    permissions={{
                        hasPermissionToEditContract,
                        hasPermissionToReadContract,
                        hasPermissionToCreateContract,
                        hasPermissionToReviewContract,
                        hasPermissionToCreateOrder,
                        hasPermissionToEditOutcome,
                    }}
                    hasPagination
                    pagesCount={contractsList?.data?.itemCount}
                    viewingOptions={viewingOptions}
                    emptyMsg="No contracts found!"
                    callbacks={{
                        viewContract: (id: string) =>
                            navigate(`/contract/view-contract/${id}/`),
                        editContract: (
                            id: string,
                            editOnlyICD?: boolean,
                            values?: {
                                icdNo: string | null;
                                icdUrl: string | null;
                                reference: string;
                            },
                        ) =>
                            editOnlyICD
                                ? setContractIcd({
                                      id,
                                      oldIcdNo: values?.icdNo,
                                      oldIcdUrl: values?.icdUrl,
                                      reference: values?.reference,
                                  })
                                : navigate(`/contract/edit-contract/${id}/`),
                        changeContractDate: (
                            contractId: string,
                            reference: string,
                            validFrom: string,
                            validTo: string,
                        ) => {
                            setContractDate({
                                contractId,
                                reference,
                                validFrom,
                                validTo,
                            });
                        },
                        duplicateContract: (id: string) =>
                            navigate(`/contract/duplicate-contract/${id}/`),
                        uploadDocument: (id: string) =>
                            navigate(`/contract/upload-file/${id}/`),
                        cancelContract: (id: string, reference: string) =>
                            setContractToCancel({ id, reference }),
                    }}
                />
            </Box>
        </Fragment>
    );
};
export default Contracts;
