import { paginationState } from "@atoms";
import { useAxios } from "./useAxios";

// Type
import type { Api } from "@types";

// Utils
import { isSuccessfulCall, startDownload } from "@utils";
import { useLayoutEffect, useRef } from "react";
import { useRecoilValue } from "recoil";

const BASE_URI = `/v0`;

/**
 * Upload a claim file
 */

type ClaimType = {
    fileKey: string;
    fileName: string;
    periodFrom: string;
    periodTo: string;
    contractPartnerId: string;
    contractId: string;
    claimCustomerReference?: string | undefined;
    claimCustomerInfo?: string | undefined;
    claimDocumentDate?: string | undefined;
    claimDueDate?: string | undefined;
    override?: boolean | undefined;
};

export const useUploadClaim = () => {
    const {
        response: uploadingResponse,
        loading: uploading,
        fetch: uploadFile,
        error: uploadError,
    }: Api = useAxios(
        {
            method: "POST",
            url: `${BASE_URI}/claim`,
        },
        { errorHandler: `Failed to upload the claim file` },
    );

    const {
        response: validationResponse,
        loading: validating,
        fetch: validateClaimFile,
        error: validationError,
        forceReset,
    }: Api = useAxios(
        {
            method: "POST",
            url: `${BASE_URI}/claim/validate`,
        },
        {
            errorHandler: `Failed to validate the claim file`,
            noSnackbarStatuses: [409],
        },
    );

    return {
        loading: { uploading, validating },
        error: { uploadError, validationError },
        response: { validationResponse, uploadingResponse },
        forceReset,
        validate: async ({
            fileKey,
            fileName,
            periodFrom,
            periodTo,
            contractPartnerId,
            contractId,
            claimCustomerInfo,
            claimCustomerReference,
            claimDocumentDate,
            claimDueDate,
        }) => {
            const res = await validateClaimFile({
                data: {
                    fileKey,
                    fileName,
                    periodFrom,
                    periodTo,
                    contractPartnerId,
                    contractId,
                    claimCustomerInfo,
                    claimCustomerReference,
                    claimDocumentDate,
                    claimDueDate,
                },
                url: `${BASE_URI}/claim/validate`,
            });
            return res;
        },
        upload: async ({
            fileKey,
            fileName,
            periodFrom,
            periodTo,
            contractPartnerId,
            contractId,
            claimCustomerInfo,
            claimCustomerReference,
            claimDocumentDate,
            claimDueDate,
            override,
        }: ClaimType) => {
            const res = await uploadFile({
                data: {
                    fileKey,
                    fileName,
                    periodFrom,
                    periodTo,
                    contractPartnerId,
                    contractId,
                    claimCustomerInfo,
                    claimCustomerReference,
                    claimDocumentDate,
                    claimDueDate,
                    override: override || undefined,
                },
                url: `${BASE_URI}/claim`,
            });
            return res;
        },
    };
};

/**
 * Fetch claim list
 */
export const useGetClaim = (priceCorrection = false, getList = true) => {
    const pagination = useRecoilValue<any>(paginationState);
    const currentUrlRef = useRef("");

    currentUrlRef.current = `${BASE_URI}/claims?page=${pagination.page}&&priceCorrection=${priceCorrection}`;

    const listAxios: Api = useAxios(
        { method: "GET", url: currentUrlRef.current },

        {
            errorHandler: `Failed to fetch ${
                priceCorrection ? "price correction claims" : "claims"
            } list`,
        },
    );

    const cancelAxios: Api = useAxios(
        { method: "POST", url: `${BASE_URI}/claim` },

        { errorHandler: `Failed to download file` },
    );

    const deleteAxios: Api = useAxios({ method: "DELETE" });

    const approveAxios: Api = useAxios({ method: "POST" });

    const editAxios: Api = useAxios({ method: "POST" });

    const recallAxios: Api = useAxios({ method: "POST" });

    useLayoutEffect(() => {
        if (!getList) return;
        listAxios.fetch?.({ url: currentUrlRef.current });
    }, []);

    const load = () => {
        if (!listAxios.fetch)
            return Promise.reject("Fetch method not available");
        return listAxios.fetch({
            url: currentUrlRef.current,
        });
    };

    return {
        list: listAxios.response,
        loading: {
            fetching: listAxios.loading,
            canceling: cancelAxios.loading,
            deleting: deleteAxios.loading,
            approving: approveAxios.loading,
            editing: editAxios.loading,
            recalling: recallAxios.loading,
        },
        error: {
            list: listAxios.error,
            cancelError: cancelAxios.error,
            deleteError: deleteAxios.error,
            approveError: approveAxios.error,
            editError: editAxios.error,
            recallError: recallAxios.error,
        },
        reload: load,
        search: (searchParams: string) => {
            if (!listAxios.fetch)
                return Promise.reject("Fetch method not available");
            return listAxios.fetch({
                url: `${currentUrlRef.current}${searchParams}`,
            });
        },

        cancel: (
            claimHeaderId: string,
            filename: string,
            reasonCancellation,
        ) => {
            if (!cancelAxios.fetch)
                return Promise.reject("Fetch method not available");
            return cancelAxios.fetch(
                {
                    url: `${BASE_URI}/claim/${claimHeaderId}/cancel`,
                    data: { reasonCancellation },
                },
                {
                    errorHandler: `Failed to request claim cancellation`,
                    successHandler: `Claim cancellation for ${filename} was successfully requested`,
                },
            );
        },
        delete: (claimHeaderId: string, filename: string) => {
            if (!deleteAxios.fetch)
                return Promise.reject("Delete method not available");
            return deleteAxios.fetch(
                {
                    url: `${BASE_URI}/claim/${claimHeaderId}`,
                },
                {
                    errorHandler: `Failed to delete ${filename}`,
                    successHandler: `Claim (${filename}) was successfully deleted`,
                },
            );
        },

        approve: (claimHeaderId: string, filename: string) => {
            if (!approveAxios.fetch)
                return Promise.reject("Approve method not available");
            return approveAxios.fetch(
                {
                    url: `${BASE_URI}/claim/${claimHeaderId}/approve`,
                },
                {
                    errorHandler: `Failed to send ${filename} for approval`,
                    successHandler: `Claim (${filename}) was successfully sent for approval`,
                },
            );
        },
        edit: (claimHeaderId: string, filename: string, data) => {
            if (!editAxios.fetch)
                return Promise.reject("Edit method not available");
            return editAxios.fetch(
                {
                    url: `${BASE_URI}/claim/${claimHeaderId}/edit`,
                    data,
                },
                {
                    errorHandler: `Failed to edit ${filename}`,
                    successHandler: `Claim (${filename}) was successfully updated`,
                },
            );
        },
        recall: (claimHeaderId: string, filename: string) => {
            if (!recallAxios.fetch)
                return Promise.reject("Recall method not available");
            return recallAxios.fetch(
                {
                    url: `${BASE_URI}/claim/${claimHeaderId}/recall`,
                },
                {
                    errorHandler: `Failed to recall ${filename}`,
                    successHandler: `Claim (${filename}) has been recalled successfully`,
                },
            );
        },
    };
};

/**
 * Fetch claim by Id
 */
export const useGetClaimById = () => {
    const {
        response: list,
        loading,
        error,
        fetch,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/claim`,
        },
        { errorHandler: `Failed to fetch claim details` },
    );

    const {
        loading: downloadingClaimDetails,
        error: pdfLocationError,
        fetch: fetchClaimDetailsPdfLocation,
    }: Api = useAxios({
        method: "GET",
    });

    const {
        loading: downloadingClaimInvoice,
        error: invoicePdfLocationError,
        fetch: fetchClaimInvoicePdfLocation,
    }: Api = useAxios({
        method: "GET",
    });

    const {
        loading: downloadingClaimAttachment,
        error: claimAttachmentLocationError,
        fetch: fetchClaimAttachmentLocation,
    }: Api = useAxios({
        method: "GET",
    });

    const {
        loading: downloading,
        error: downloadError,
        fetch: download,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/claim`,
        },
        { errorHandler: `Failed to download file` },
    );

    const {
        loading: editingNotes,
        error: editNotesError,
        fetch: editNotes,
    }: Api = useAxios(
        {
            method: "PUT",
        },
        { errorHandler: `Failed to edit claim notes` },
    );

    const {
        loading: uploadingAttachments,
        error: uploadAttachmentsError,
        fetch: uploadAttachments,
    }: Api = useAxios({
        method: "PUT",
    });

    return {
        list,
        loading: {
            loading,
            downloading,
            downloadingClaimDetails,
            downloadingClaimInvoice,
            editingNotes,
            uploadingAttachments,
            downloadingClaimAttachment,
        },
        error: {
            error,
            downloadError,
            pdfLocationError,
            invoicePdfLocationError,
            editNotesError,
            uploadAttachmentsError,
            claimAttachmentLocationError,
        },
        load: (claimId: string) =>
            fetch({ url: `${BASE_URI}/claim/${claimId}` }),
        download: (
            claimId: string,
            type:
                | "BPF"
                | "CLAIM"
                | "CLAIM_CUSTOMER"
                | "CLAIM_VALIDATION"
                | "CLAIM_PRICE_CORRECTION",
            name: string,
        ) =>
            download(
                {
                    url: `${BASE_URI}/claim/${claimId}/file?type=${type}`,
                },
                {
                    errorHandler: `Failed to download ${name}`,
                },
            ).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
                return res;
            }),
        downloadClaimDetails: (claimId: string) =>
            fetchClaimDetailsPdfLocation(
                {
                    url: `${BASE_URI}/pdf/claim/${claimId}`,
                },
                {
                    errorHandler: `Failed to fetch ClaimDetails.pdf`,
                },
            ).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
                return res;
            }),
        downloadClaimInvoice: (claimId: string, fileName: string) =>
            fetchClaimInvoicePdfLocation(
                {
                    url: `${BASE_URI}/pdf/claim/${claimId}/invoice`,
                },
                {
                    errorHandler: `Failed to fetch ${fileName}`,
                },
            ).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
                return res;
            }),
        downloadClaimAttachment: (
            claimAttachmentId: string,
            fileName: string,
        ) =>
            fetchClaimAttachmentLocation(
                {
                    url: `${BASE_URI}/claim/attachment/${claimAttachmentId}/download`,
                },
                {
                    errorHandler: `Failed to fetch ${fileName}`,
                },
            ).then(res => {
                if (isSuccessfulCall(res?.status) && !!res?.data?.location) {
                    startDownload(res.data.location);
                    return res;
                }
                return res;
            }),
        editNotes: async (claimId, notes) =>
            editNotes(
                {
                    url: `${BASE_URI}/claim/${claimId}/note`,
                    data: { notes },
                },
                {
                    successHandler: `Notes were successfully submitted`,
                },
            ),

        uploadAttachments: async (claimHeaderId: string, files: Array<any>) => {
            return uploadAttachments(
                {
                    url: `${BASE_URI}/claim/${claimHeaderId}/attachment`,
                    data: { attachments: files },
                },
                {
                    errorHandler: `Failed to upload some files`,
                    successHandler: `Files were successfully uploaded`,
                },
            );
        },
    };
};
