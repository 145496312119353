// Libs
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import React, { Fragment, useMemo } from "react";

// Own components
import { FieldRenderer, Card, EditClaimNotes, Dropzone } from "@components";

//Types
import type { Location } from "@types";

// Hooks
import { useSelectedCountry } from "@hooks";

//Utils
import { constantMapper, displayDate, isArrayWithContent } from "@utils";

/**
 * Claim file type
 */
type ClaimFiles = {
    claim: {
        fileName: string;
        type: "CLAIM";
    };
    validationReport: {
        fileName: string;
        type: "CLAIM_VALIDATION";
    };
    customerReport: {
        fileName: string;
        type: "CLAIM_CUSTOMER";
    };
    bpf: {
        fileName: string;
        type: "BPF";
    };
    claimPriceCorrection: {
        fileName: string;
        type: "CLAIM_PRICE_CORRECTION";
    };
    claimInvoice: {
        fileName: string;
        type: "CLAIM_CREDIT_NOTE";
    };
    claimDetails: {
        fileName: string;
    };
    bpfPriceCorrection: {
        fileName: string;
        type: "BPF_PRICE_CORRECTION";
    };
};

/**
 * Props type
 */
interface Props {
    location?: Location;
    children?: React.ReactNode;
    claimId: string;
    id?: string;
    supplierName: string;
    status: string;
    dateFrom: string;
    dateTo: string;
    fileName: string;
    fileDate: string;
    dateProcessed: string;
    action: string;
    reasonCancellation: string;
    reasonOverride: string;
    reasonRejection: string;
    approvedTotalRebateValue: string;
    totalRebateValue: string;
    differenceTotalRebateValue: string;
    totalValue: string;
    differenceTotalValue: string;
    totalUnits: string;
    evidenceTotalRebateValue: string;
    differenceTotalUnits: string;
    evidenceTotalValue: string;
    evidenceTotalUnits: string;
    vatIncluded: boolean;
    vatPercentage: string;
    valueCheck: boolean;
    priceCheck: boolean;
    percentageCheck: boolean;
    rebateCheck: boolean;
    downloading: boolean;
    claimFiles: ClaimFiles;
    unitsCheck: boolean;
    claimCustomerReference?: string;
    claimDueDate?: string;
    claimDocumentDate?: string;
    downloadClaimData?: (
        claimId: string,
        type: string,
        fileName: string,
    ) => Promise<any>;
    hasTitle?: boolean;
    shrinkColumns?: boolean;
    isTaskOverview?: boolean;
    // override: boolean;
    //overriddenTotalRebateValue: string;
    isApproved?: boolean;
    differenceTotalUnitsPercentage?: string;
    differenceTolerance?: string;
    contractRef?: string;
    priceCorrection?: {
        priceCorrectionSum: string;
        priceCorrectionRebateSum: string;
        priceCorrectionRefundSum: string;
        priceCorrectionStatus: string;
        approvedPriceCorrectionRefundValue: string;
    };
    downloadingClaimDetails: boolean;
    downloadingClaimInvoice?: boolean;
    downloadClaimDetails: (claimId: string) => void;
    downloadClaimInvoice?: (claimId: string, fileName: string) => void;
    claimReferenceNumber?: string;
    isClaimDetailsTask?: boolean;
    isEditClaim?: boolean;
    files?: Array<any>;
    uploadFiles?: any;
    storedNotes?: Array<{ note: string; noteInfo: string }>;
    setNotes?: any;
    editNote?: any;
    setEditNote?: any;
    disabled?: boolean;
    descriptions?: string[];
    onDescriptionChange?: (
        type: "delete" | "edit",
        index: number,
        value: string,
        attachmentsType?: "stored" | "newAttachments",
    ) => void;
    onDownloadAttachment?: (index: number) => void;
    storedAttachments?: Array<any>;
    onAttachmentRemove?: (
        idx: number,
        attachmentsType: "stored" | "newAttachments",
    ) => void;
    attachments?: Array<any>;
    notes?: Array<{ note: string; noteInfo: string }>;
    currency?: string;
    sapInfo?: Array<{ sapOrderNo: string | null; sapInvoiceNo: string | null }>;
}

/**
 * Claim details
 */
const ClaimDetails = ({
    claimId,
    downloadClaimData,
    id,
    supplierName,
    status,
    dateFrom,
    dateTo,
    fileName,
    fileDate,
    dateProcessed,
    action,
    reasonCancellation,
    reasonOverride,
    reasonRejection,
    approvedTotalRebateValue,
    totalRebateValue,
    totalValue,
    totalUnits,
    evidenceTotalRebateValue,
    evidenceTotalValue,
    evidenceTotalUnits,
    vatIncluded,
    vatPercentage,
    valueCheck,
    priceCheck,
    percentageCheck,
    rebateCheck,
    unitsCheck,
    downloading,
    claimFiles,
    hasTitle = true,
    shrinkColumns,
    isTaskOverview = false,
    differenceTotalValue,
    differenceTotalRebateValue,
    differenceTotalUnits,
    differenceTotalUnitsPercentage,
    differenceTolerance,
    contractRef,
    claimReferenceNumber,
    priceCorrection,
    downloadingClaimDetails,
    downloadClaimDetails,
    downloadingClaimInvoice,
    downloadClaimInvoice,
    isClaimDetailsTask,
    isEditClaim,
    files,
    uploadFiles,
    storedNotes,
    setNotes,
    editNote,
    setEditNote,
    disabled,
    descriptions,
    onDescriptionChange,
    onDownloadAttachment,
    storedAttachments,
    onAttachmentRemove,
    attachments,
    notes,
    claimCustomerReference,
    claimDueDate,
    claimDocumentDate,
    sapInfo,
}: Props) => {
    const { isUkTeam } = useSelectedCountry();

    /**
     * SAP info mapper
     */
    const { mappedSapOrderNos, mappedSapInvoiceNos } = useMemo(() => {
        if (!sapInfo || !isArrayWithContent(sapInfo))
            return { mappedSapOrderNos: [], mappedSapInvoiceNos: [] };

        const mappedSapOrderNos = sapInfo
            .map(info => info.sapOrderNo)
            .filter(orderNo => orderNo !== null && orderNo !== undefined);

        const mappedSapInvoiceNos = sapInfo
            .map(info => info.sapInvoiceNo)
            .filter(invoiceNo => invoiceNo !== null && invoiceNo !== undefined);

        return { mappedSapOrderNos, mappedSapInvoiceNos };
    }, [sapInfo]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Card
                title={hasTitle ? `Claim Details` : undefined}
                id={`${id}-claim-file`}
                firstItemOnScreen
            >
                <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                    <Grid item xs={12} my={2}>
                        <Typography variant="h3">General</Typography>
                    </Grid>

                    {!!claimReferenceNumber && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-claim-ref-no`}
                                label={"Claim reference number"}
                                value={claimReferenceNumber}
                            />
                        </Grid>
                    )}

                    {!isUkTeam && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-contract-ref`}
                                label="Contract reference"
                                value={contractRef}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                        <FieldRenderer
                            id={`${id}-supplier`}
                            label="Supplier"
                            value={supplierName}
                        />
                    </Grid>
                    {!isTaskOverview && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-status`}
                                label="Status"
                                value={constantMapper(status)}
                            />
                        </Grid>
                    )}
                    {!!claimCustomerReference && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-claim-ref-no`}
                                label={"Partner claim reference"}
                                value={claimCustomerReference}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                        <FieldRenderer
                            id={`${id}-date-from`}
                            label="Date from"
                            value={displayDate(dateFrom)}
                        />
                    </Grid>
                    <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                        <FieldRenderer
                            id={`${id}-date-to`}
                            label="Date to"
                            value={displayDate(dateTo)}
                        />
                    </Grid>

                    {!!claimDocumentDate && !!isUkTeam && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-claim-document-date`}
                                label={"Claim document date"}
                                value={displayDate(claimDocumentDate)}
                            />
                        </Grid>
                    )}

                    {!!claimDueDate && !!isUkTeam && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-claim-document-date`}
                                label={"Claim due date"}
                                value={displayDate(claimDueDate)}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                        <FieldRenderer
                            id={`${id}-file-name`}
                            label="File name"
                            value={fileName}
                            showTooltip
                        />
                    </Grid>
                    {!isTaskOverview && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-file-date`}
                                label="File date"
                                value={displayDate(fileDate)}
                            />
                        </Grid>
                    )}
                    {!isTaskOverview && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-date-processed`}
                                label="Date processed"
                                value={displayDate(dateProcessed)}
                            />
                        </Grid>
                    )}
                    {!isTaskOverview && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-action`}
                                label="Action"
                                value={action}
                            />
                        </Grid>
                    )}

                    {!!mappedSapOrderNos &&
                        isArrayWithContent(mappedSapOrderNos) && (
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-claim-sap-order-no`}
                                    label={"SAP order number (s)"}
                                    value={mappedSapOrderNos
                                        .map(el => el)
                                        .join(", ")}
                                />
                            </Grid>
                        )}
                    {!!mappedSapInvoiceNos &&
                        isArrayWithContent(mappedSapInvoiceNos) && (
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-claim-sap-invoice-no`}
                                    label={"SAP invoice number (s)"}
                                    value={mappedSapInvoiceNos
                                        .map(el => el)
                                        .join(", ")}
                                />
                            </Grid>
                        )}

                    {!isUkTeam && (
                        <Fragment>
                            <Grid item xs={12} my={2}>
                                <Divider />
                                <Typography variant="h3" pt={4}>
                                    Sales
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-total-value-claim`}
                                    label="Sales"
                                    value={totalValue}
                                />
                            </Grid>

                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-total-value-evidence`}
                                    label="Sales evidence"
                                    value={evidenceTotalValue}
                                />
                            </Grid>

                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-value-check`}
                                    label="Value check"
                                    value={valueCheck ? "Yes" : " No"}
                                    coloredBg={
                                        isClaimDetailsTask
                                            ? undefined
                                            : valueCheck
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-difference-total-value`}
                                    label="Difference total value"
                                    value={differenceTotalValue}
                                />
                            </Grid>

                            <Grid item xs={12} my={2}>
                                <Divider />
                                <Typography variant="h3" pt={4}>
                                    Units and price
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-total-units-claim`}
                                    label="Units"
                                    value={totalUnits}
                                />
                            </Grid>

                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-total-units-evidence`}
                                    label="Units evidence"
                                    value={evidenceTotalUnits}
                                />
                            </Grid>
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-units-check`}
                                    label="Units check"
                                    value={unitsCheck ? "Yes" : "No"}
                                    coloredBg={
                                        isClaimDetailsTask
                                            ? undefined
                                            : unitsCheck
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-difference-total-units`}
                                    label="Difference total units"
                                    value={differenceTotalUnits}
                                />
                            </Grid>
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-difference-units-percentage`}
                                    label="Units diff (%)"
                                    value={
                                        differenceTotalUnitsPercentage
                                            ? `${differenceTotalUnitsPercentage}%`
                                            : undefined
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-difference-units-tolerance`}
                                    label="Units difference tolerance (%)"
                                    value={
                                        differenceTolerance
                                            ? `${differenceTolerance}%`
                                            : undefined
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-price-check`}
                                    label="Price check"
                                    value={priceCheck ? "Yes" : " No"}
                                    coloredBg={
                                        isClaimDetailsTask
                                            ? undefined
                                            : priceCheck
                                    }
                                />
                            </Grid>
                        </Fragment>
                    )}

                    <Grid item xs={12} my={2}>
                        <Divider />
                        <Typography variant="h3" pt={4}>
                            Rebate and discount
                        </Typography>
                    </Grid>
                    {isUkTeam ? (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-total-total-net-rebate-per-customer-claim`}
                                label="Total net rebate per customer claim"
                                value={totalRebateValue}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-total-rebate-claim`}
                                label="Rebate"
                                value={totalRebateValue}
                            />
                        </Grid>
                    )}
                    {isUkTeam ? (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-total-net-rebate-per-evidence`}
                                label="Total net rebate per evidence"
                                value={evidenceTotalRebateValue}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-total-rebate-evidence`}
                                label="Rebate evidence"
                                value={evidenceTotalRebateValue}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                        <FieldRenderer
                            id={`${id}-rebate-check`}
                            label="Rebate check"
                            value={rebateCheck ? "Yes" : " No"}
                            coloredBg={
                                isClaimDetailsTask ? undefined : rebateCheck
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                        <FieldRenderer
                            id={`${id}-difference-total-rebate-value`}
                            label="Difference total rebate value"
                            value={differenceTotalRebateValue}
                        />
                    </Grid>

                    {isUkTeam ? (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-VAT-to-be-added-in-SAP`}
                                label="VAT to be added in SAP"
                                value={vatIncluded ? "Yes" : " No"}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-including-VAT`}
                                label="Including VAT"
                                value={vatIncluded ? "Yes" : " No"}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                        <FieldRenderer
                            id={`${id}-VAT-percentage`}
                            label="VAT percentage"
                            value={
                                vatIncluded
                                    ? vatPercentage
                                        ? `${vatPercentage}%`
                                        : ""
                                    : "NA"
                            }
                        />
                    </Grid>

                    {!isUkTeam && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-percentage-check`}
                                label="Percentage check"
                                value={percentageCheck ? "Yes" : " No"}
                                coloredBg={
                                    isClaimDetailsTask
                                        ? undefined
                                        : percentageCheck
                                }
                            />
                        </Grid>
                    )}

                    {priceCorrection && (
                        <Fragment>
                            <Grid item xs={12} my={2}>
                                <Divider />
                                <Typography variant="h3" pt={4}>
                                    Price correction
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-price-correction-value`}
                                    label="Price correction value"
                                    value={priceCorrection?.priceCorrectionSum}
                                />
                            </Grid>

                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-price-correction-rebate`}
                                    label="Price correction rebate"
                                    value={
                                        priceCorrection?.priceCorrectionRebateSum
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-price-correction-refund`}
                                    label="Price correction refund"
                                    value={
                                        priceCorrection?.priceCorrectionRefundSum
                                    }
                                    coloredBg={true}
                                />
                            </Grid>
                            {priceCorrection?.priceCorrectionStatus && (
                                <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                    <FieldRenderer
                                        id={`${id}-price-correction-status`}
                                        label="Price correction status"
                                        value={constantMapper(
                                            priceCorrection?.priceCorrectionStatus,
                                        )}
                                    />
                                </Grid>
                            )}
                        </Fragment>
                    )}
                    {!isClaimDetailsTask && (
                        <Fragment>
                            <Grid item xs={12} my={2}>
                                <Divider />
                                <Typography variant="h3" pt={4}>
                                    Approval
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-approved-total-rebate-value`}
                                    label="Approved total rebate value"
                                    value={
                                        approvedTotalRebateValue
                                            ? approvedTotalRebateValue
                                            : "NA"
                                    }
                                />
                            </Grid>

                            {priceCorrection && (
                                <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                    <FieldRenderer
                                        id={`${id}-approved-price-correction-refund-value`}
                                        label="Approved price correction refund value"
                                        value={
                                            priceCorrection?.approvedPriceCorrectionRefundValue
                                                ? priceCorrection?.approvedPriceCorrectionRefundValue
                                                : "NA"
                                        }
                                    />
                                </Grid>
                            )}
                        </Fragment>
                    )}

                    {reasonCancellation && (
                        <Grid item xs={12}>
                            <FieldRenderer
                                id={`${id}-reason-cancellation`}
                                label="Reason cancellation"
                                value={reasonCancellation}
                                multiline
                            />
                        </Grid>
                    )}
                    {reasonOverride && (
                        <Grid item xs={12}>
                            <FieldRenderer
                                id={`${id}-reason-override`}
                                label="Reason override"
                                value={reasonOverride}
                                multiline
                            />
                        </Grid>
                    )}
                    {reasonRejection && (
                        <Grid item xs={12}>
                            <FieldRenderer
                                id={`${id}-reason-rejection`}
                                label="Reason rejection"
                                value={reasonRejection}
                                multiline
                            />
                        </Grid>
                    )}
                </Grid>
            </Card>

            <Card title="Attachments" id={`${id}-claim-file-attachments`}>
                {isEditClaim ? (
                    <Grid item xs={12} container>
                        <Grid item xs={12}>
                            <Dropzone
                                variant="big"
                                id={"upload-claim-files"}
                                fileTypes={{
                                    "application/pdf": [".pdf"],
                                    "image/png": [".png"],
                                    "image/jpeg": [".jpeg", ".jpg"],
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                        [".docx"],
                                    "application/msword": [".doc"],
                                    "application/vnd.ms-excel": [".xls"],
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                        [".xlsx"],
                                    "application/vnd.ms-outlook": [".msg"],
                                    "text/plain": [".txt"],
                                }}
                                files={files || []}
                                onUpload={newFiles => uploadFiles(newFiles)}
                                onAttachmentRemove={onAttachmentRemove}
                                maxNameLength="114"
                                multiple
                                disabled={disabled}
                                hasFileDescription
                                descriptions={descriptions}
                                onDescriptionChange={onDescriptionChange}
                                canDownloadAttachments
                                onDownloadAttachment={onDownloadAttachment}
                                storedAttachments={storedAttachments}
                                downloading={downloading}
                            />
                        </Grid>
                    </Grid>
                ) : attachments && isArrayWithContent(attachments) ? (
                    attachments.map((file, idx) => (
                        <Grid
                            item
                            xs={12}
                            container
                            key={idx}
                            alignItems={"flex-end"}
                            justifyContent={"space-between"}
                            columnSpacing={1}
                            mb={2}
                        >
                            <Grid item xs={4}>
                                <FieldRenderer
                                    id={`${id}-attachment-${idx}-fileName`}
                                    value={file.fileName}
                                    loading={downloading}
                                    hasDownloadAction
                                    onDownloadClick={
                                        onDownloadAttachment
                                            ? () => onDownloadAttachment(idx)
                                            : undefined
                                    }
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FieldRenderer
                                    id={`${id}-attachment-${idx}-fileDescription`}
                                    label={"Description"}
                                    value={file.fileDescription}
                                />
                            </Grid>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12} container justifyContent={"center"}>
                        <Typography variant="caption1">
                            No attachments found
                        </Typography>
                    </Grid>
                )}
            </Card>

            <Card title="Notes" id={`${id}-claim-file-notes`}>
                <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                    {isEditClaim ? (
                        <EditClaimNotes
                            id={`${id}-edit-claim-notes`}
                            storedNotes={storedNotes || []}
                            setNotes={setNotes}
                            editNote={editNote}
                            setEditNote={setEditNote}
                            disabled={disabled}
                        />
                    ) : !!notes && isArrayWithContent(notes) ? (
                        notes.map((note, idx) => (
                            <Grid item xs={12} mb={2} key={idx}>
                                <FieldRenderer
                                    id={`${id}-note-${idx}-note`}
                                    value={note.note}
                                    label="Note"
                                    sx={{ mb: 2 }}
                                />
                                <FieldRenderer
                                    id={`${id}-note-${idx}-note-info`}
                                    value={note.noteInfo}
                                    label="Note info"
                                    multiline
                                />
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12} container justifyContent={"center"}>
                            <Typography variant="caption1">
                                No notes found
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Card>

            <Card title="Claim files" id={`${id}-claim-file`}>
                <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-original-claim-file`}
                            label="Claim upload file"
                            loading={downloading}
                            showTooltip
                            disabledAction={
                                downloading || downloadingClaimDetails
                            }
                            value={claimFiles?.claim?.fileName}
                            hasDownloadAction
                            onDownloadClick={
                                downloadClaimData
                                    ? () => {
                                          downloadClaimData(
                                              claimId,
                                              claimFiles?.claim.type,
                                              claimFiles?.claim.fileName,
                                          );
                                      }
                                    : undefined
                            }
                        />
                    </Grid>
                    {!!claimFiles?.validationReport?.fileName && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-claim-validation-report`}
                                label="Claim validation report"
                                value={claimFiles?.validationReport?.fileName}
                                disabledAction={
                                    downloading || downloadingClaimDetails
                                }
                                hasDownloadAction
                                showTooltip
                                loading={downloading}
                                onDownloadClick={
                                    downloadClaimData
                                        ? () => {
                                              downloadClaimData(
                                                  claimId,
                                                  claimFiles?.validationReport
                                                      .type,
                                                  claimFiles?.validationReport
                                                      .fileName,
                                              );
                                          }
                                        : undefined
                                }
                            />
                        </Grid>
                    )}

                    {!isUkTeam && claimFiles?.customerReport?.fileName && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-claim-customer-report`}
                                label="Claim customer report"
                                showTooltip
                                loading={downloading}
                                value={claimFiles?.customerReport?.fileName}
                                disabledAction={
                                    downloading || downloadingClaimDetails
                                }
                                hasDownloadAction
                                onDownloadClick={
                                    downloadClaimData
                                        ? () =>
                                              downloadClaimData(
                                                  claimId,
                                                  claimFiles?.customerReport
                                                      .type,
                                                  claimFiles?.customerReport
                                                      .fileName,
                                              )
                                        : undefined
                                }
                            />
                        </Grid>
                    )}

                    {claimFiles?.claimPriceCorrection?.type && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-claim-price-correction-report`}
                                label="Claim price correction report"
                                value={
                                    claimFiles?.claimPriceCorrection
                                        ?.fileName || "Price Correction report"
                                }
                                disabledAction={downloading}
                                hasDownloadAction
                                showTooltip
                                loading={downloading}
                                onDownloadClick={
                                    downloadClaimData
                                        ? () =>
                                              downloadClaimData(
                                                  claimId,
                                                  claimFiles
                                                      ?.claimPriceCorrection
                                                      ?.type,
                                                  claimFiles
                                                      ?.claimPriceCorrection
                                                      .fileName,
                                              )
                                        : undefined
                                }
                            />
                        </Grid>
                    )}

                    {!!claimFiles?.bpf?.fileName && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-bpf-file`}
                                label="BPF file"
                                showTooltip
                                value={claimFiles?.bpf?.fileName}
                                disabledAction={
                                    downloading || downloadingClaimDetails
                                }
                                loading={downloading}
                                hasDownloadAction
                                onDownloadClick={
                                    downloadClaimData
                                        ? () =>
                                              downloadClaimData(
                                                  claimId,
                                                  claimFiles?.bpf.type,
                                                  claimFiles?.bpf.fileName ||
                                                      "BPF file",
                                              )
                                        : undefined
                                }
                            />
                        </Grid>
                    )}

                    {!!claimFiles?.bpfPriceCorrection?.fileName && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`${id}-bpf-price-correction`}
                                label="BPF price correction file"
                                showTooltip
                                value={claimFiles?.bpfPriceCorrection?.fileName}
                                disabledAction={
                                    downloading || downloadingClaimDetails
                                }
                                loading={downloading}
                                hasDownloadAction
                                onDownloadClick={
                                    downloadClaimData
                                        ? () =>
                                              downloadClaimData(
                                                  claimId,
                                                  claimFiles?.bpfPriceCorrection
                                                      ?.type,
                                                  claimFiles?.bpfPriceCorrection
                                                      ?.fileName ||
                                                      "BPF price correction file",
                                              )
                                        : undefined
                                }
                            />
                        </Grid>
                    )}

                    {status !== "NEW" &&
                        !!downloadClaimDetails &&
                        !!claimFiles?.claimDetails.fileName && (
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-claim-details-pdf`}
                                    label="Claim Details PDF"
                                    showTooltip
                                    loading={downloadingClaimDetails}
                                    value={
                                        claimFiles?.claimDetails.fileName ||
                                        "ClaimDetails.pdf"
                                    }
                                    disabledAction={
                                        downloading || downloadingClaimDetails
                                    }
                                    hasDownloadAction
                                    onDownloadClick={() =>
                                        downloadClaimDetails(claimId)
                                    }
                                />
                            </Grid>
                        )}

                    {!!downloadClaimInvoice &&
                        !!claimFiles?.claimInvoice?.fileName && (
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-claim-invoice-pdf`}
                                    label="Credit note PDF"
                                    showTooltip
                                    loading={downloadingClaimInvoice}
                                    value={claimFiles?.claimInvoice?.fileName}
                                    disabledAction={
                                        downloading || downloadingClaimInvoice
                                    }
                                    hasDownloadAction
                                    onDownloadClick={() =>
                                        downloadClaimInvoice(
                                            claimId,
                                            claimFiles?.claimInvoice?.fileName,
                                        )
                                    }
                                />
                            </Grid>
                        )}
                </Grid>
            </Card>
        </Fragment>
    );
};

export default ClaimDetails;
